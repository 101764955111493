<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Certificacion',

    metaInfo: { title: 'Certificación' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        //'ConvocatoriaCertificacion',
       // 'ConvocatoriaRecertificacion',
        //'CitatorioAsamblea',
        'que-certificacion',
        'social-certificacion',

        // 'pricing-plan',
        //'customer-reviews',
       // 'about-our-product',
        //'info-graph',
       // 'marketing',
        //'features',
        //'mobile',
        //'contact-us',
        //'keep-in-touch',
        'info-alt',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'pricing-plans',
      },
    },
  }
</script>
